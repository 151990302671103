<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :brands-filter="brandsFilter"
        :date-filter="dateFilter"
        :status-filter="statusFilter"
        :suppliers-filter="suppliersFilter"
        :total-filter="totalFilter"
        :type-filter="typeFilter"
        @updateBrandsFilter="updateBrandsFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
        @updateSuppliersFilter="updateSuppliersFilter($event)"
        @updateTotalFilter="updateTotalFilter($event)"
        @updateTypeFilter="updateTypeFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        :search-type="searchType"
        :search-options="searchOptions"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      brandsFilter: null,
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      itemPerPage: 50,
      searchKey: null,
      searchType: 0,
      searchOptions: [
        { type: "code", label: "Theo mã chiết khấu" },
        { type: "import_code", label: "Theo mã phiếu nhập" }
      ],
      statusFilter: null,
      suppliersFilter: null,
      totalFilter: {
        from: null,
        to: null
      },
      typeFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getRebates(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getRebates(route.query);
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    async getRebates(query) {
      if (query.page) {
        // Set filtered brands
        this.brandsFilter =
          typeof query.brand_id === "string"
            ? [parseInt(query.brand_id)]
            : typeof query.brand_id === "object"
            ? query.brand_id.map(item => parseInt(item))
            : [];
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered status
        this.purchaseOrderFilter = query.po_id ? parseInt(query.po_id) : null;
        // Set filtered status
        this.statusFilter =
          query.status !== undefined ? parseInt(query.status) : "all";
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set total filter
        this.totalFilter = {
          from:
            query.total_from !== undefined
              ? parseFloat(query.total_from)
              : null,
          to: query.total_to !== undefined ? parseFloat(query.total_to) : null
        };
        // Set filtered type
        this.typeFilter = query.type ? parseInt(query.type) : "all";
        // Set search key
        this.searchKey = query.search || null;
        // Set search type
        this.searchType = query.search ? parseInt(query.search_type) : 0;
        // Set filtered suppliers
        this.suppliersFilter =
          typeof query.supplier_id === "string"
            ? [parseInt(query.supplier_id)]
            : typeof query.supplier_id === "object"
            ? query.supplier_id.map(item => parseInt(item))
            : [];

        // Get rebates
        await this.$store.dispatch("REBATE/getRebates", {
          filter: {
            supplier_id: this.suppliersFilter,
            brand_id: this.brandsFilter,
            type:
              this.typeFilter && this.typeFilter !== "all"
                ? [this.typeFilter]
                : null,
            status:
              this.statusFilter !== undefined &&
              this.statusFilter !== null &&
              this.statusFilter !== "all"
                ? this.statusFilter
                : null,
            fromMoney: this.totalFilter.from,
            toMoney: this.totalFilter.to,
            fromDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            toDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          search_key: this.searchOptions[this.searchType].type,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async pushRouter() {
      await this.$router.push({
        name: "transaction_goods-receipts_rebates",
        query: {
          supplier_id:
            this.suppliersFilter && this.suppliersFilter.length > 0
              ? this.suppliersFilter
              : undefined,
          brand_id:
            this.brandsFilter && this.brandsFilter.length > 0
              ? this.brandsFilter
              : undefined,
          type:
            this.typeFilter && this.typeFilter !== "all"
              ? this.typeFilter
              : undefined,
          status:
            this.statusFilter !== undefined &&
            this.statusFilter !== null &&
            this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          total_from:
            this.totalFilter.from !== null ? this.totalFilter.from : undefined,
          total_to:
            this.totalFilter.to !== null ? this.totalFilter.to : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          search_type:
            this.searchKey && this.searchKey.length > 0
              ? this.searchType
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    updateBrandsFilter(val) {
      this.brandsFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSuppliersFilter(val) {
      this.suppliersFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateTotalFilter(val) {
      this.totalFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateTypeFilter(val) {
      this.typeFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
