var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.rebateStatusRequest.value === 'loading-getGoodsReceipts',"loading-text":"Đang tải dữ liệu","items":_vm.rebates,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',[_c('tr',{staticClass:"yellow lighten-5"},[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.rebateListTotals.total_value))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.rebateListTotals.total_remain))+" ")]),_c('td')]),_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1),_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_vm._v(" "+_vm._s(item.brand_id ? "Hãng" : item.supplier_id ? "NCC" : "N/A")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.supplier_name ? item.supplier_name : "N/A")+" - "+_vm._s(item.supplier_code ? item.supplier_code : "N/A")+" ")]),_c('td',[(item.hdn_code)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.hdn_code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.hdn_code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])]):_vm._e()],1),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.total)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.left)))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.left === 0
                ? 'green'
                : item.left < item.total
                ? 'orange'
                : item.left === item.total
                ? 'grey'
                : null,"small":"","outlined":""},domProps:{"textContent":_vm._s(
              item.left === 0
                ? 'Đã trả hết'
                : item.left < item.total
                ? 'Trả một phần'
                : item.left === item.total
                ? 'Chưa trả'
                : null
            )}})],1)])})],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"8"}},[_vm._v("Không có dữ liệu")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }