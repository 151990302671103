<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="rebateStatusRequest.value === 'loading-getGoodsReceipts'"
    loading-text="Đang tải dữ liệu"
    :items="rebates"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <!-- Start: Total -->
        <tr class="yellow lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">
            {{ rebateListTotals.total_value | formatCurrency }}
          </td>
          <td class="font-weight-bold">
            {{ rebateListTotals.total_remain | formatCurrency }}
          </td>
          <td></td>
        </tr>
        <!-- Start: Total -->
        <!-- Start: Items -->
        <tr v-for="item in items" :key="item.id">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.code)"
                >
                  {{ item.code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>{{ item.created_at }}</td>
          <td>
            {{ item.brand_id ? "Hãng" : item.supplier_id ? "NCC" : "N/A" }}
          </td>
          <td>
            {{ item.supplier_name ? item.supplier_name : "N/A" }} -
            {{ item.supplier_code ? item.supplier_code : "N/A" }}
          </td>
          <td>
            <v-tooltip bottom v-if="item.hdn_code">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.hdn_code)"
                >
                  {{ item.hdn_code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>{{ item.total | formatCurrency }}</td>
          <td>{{ item.left | formatCurrency }}</td>
          <td class="text-center">
            <v-chip
              class="font-weight-bold"
              :color="
                item.left === 0
                  ? 'green'
                  : item.left < item.total
                  ? 'orange'
                  : item.left === item.total
                  ? 'grey'
                  : null
              "
              small
              outlined
              v-text="
                item.left === 0
                  ? 'Đã trả hết'
                  : item.left < item.total
                  ? 'Trả một phần'
                  : item.left === item.total
                  ? 'Chưa trả'
                  : null
              "
            >
            </v-chip>
          </td>
        </tr>
        <!-- End: Items -->
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td colspan="8">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã CKTM",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Từ",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: "NCC/Hãng",
          align: "start",
          sortable: false,
          value: "type_name"
        },
        {
          text: "Mã phiếu nhập",
          align: "start",
          sortable: false,
          value: "hdn_code"
        },
        {
          text: "Giá trị",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "Còn lại",
          align: "start",
          sortable: false,
          value: "left"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    rebateListTotals() {
      return this.$store.getters["REBATE/rebateListTotals"];
    },
    rebates() {
      return this.$store.getters["REBATE/rebates"];
    },
    rebateStatusRequest() {
      return this.$store.getters["REBATE/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfGoodsReceipt(val) {
      const arr = this.rebates.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$store.dispatch("REBATE/getGoodsReceiptById", item.id);

      await this.$router.push({
        name: "transaction_goods-receipts_notes-detail",
        params: {
          rebateId: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
